<template>
  <q-dialog :ref="$options.name" persistent>
    <q-card>
      <q-card-section>
        <div class="text-h6">Confirm Deletion</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        Are you sure you want to delete <b>{{ descriptor }}</b
        >? This action cannot be undone.
      </q-card-section>

      <q-card-actions align="between" class="q-gutter-x-md q-pa-md">
        <q-btn
          flat
          label="Cancel"
          color="subtle"
          v-close-popup
          :disable="submitting"
        />
        <q-btn
          @click="handleOkClick"
          label="Delete"
          color="primary"
          :disable="submitting"
          :loading="submitting"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ConfirmationModalDelete",
  props: {
    descriptor: {
      type: String,
      required: true
    }
  },
  emits: ["ok", "hide"],
  data() {
    return {
      submitting: false
    };
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    },
    handleOkClick() {
      this.$emit("ok", this);
      // this.hide();
    }
  }
};
</script>

<style scoped lang="scss"></style>
