<template>
  <h4 class="row justify-between items-center q-mb-lg">
    {{ title }}
    <div class="q-gutter-x-md">
      <slot name="actions" />
    </div>
  </h4>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss"></style>
