<template>
  <q-btn
    round
    flat
    @click="copyToClipboard(copyValue)"
    color="subtle"
    class="q-ml-sm"
    style="min-height: 2.5em !important;min-width: 2.5em !important; margin-top: -1px;"
  >
    <q-icon name="content_copy" size="1em" />
    <q-tooltip
      anchor="top middle"
      self="bottom middle"
      content-class="bg-primary text-white"
    >
      Copy
    </q-tooltip>
  </q-btn>
</template>

<script>
export default {
  name: "CopyToClipboardButton",
  props: {
    copyValue: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
