<template>
  <q-btn-dropdown
    flat
    rounded
    fab-mini
    style="min-height: 2.5em !important;min-width: 2.5em !important;padding:0 !important;"
    color="dark"
    dropdown-icon="more_horiz"
    no-icon-animation
  >
    <q-list>
      <q-item
        v-for="(action, i) in actions"
        :key="'ActionsDropdownButtonAction_' + i"
        clickable
        v-close-popup
        @click="action.action"
      >
        <q-item-section>
          <q-item-label>{{ action.label }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
export default {
  name: "ActionsDropdownButton",
  props: {
    actions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
