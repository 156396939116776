<template>
  <q-breadcrumbs class="q-mb-md">
    <q-breadcrumbs-el
      v-for="(breadcrumb, i) in breadcrumbs"
      :key="i + '_breadcrumb'"
      v-bind="breadcrumb"
    />
  </q-breadcrumbs>
</template>

<script>
export default {
  name: "PageDynamicBreadcrumb",
  props: {
    customCurrentPage: {
      type: String,
      required: false,
      default: ""
    },
    overridePageLevel: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    breadcrumbs() {
      let breadcrumbs = [];
      let currPath = this.$router.currentRoute.value.path.substring(1);
      let pages = currPath.split("/").filter(page => page.length > 0);
      pages.forEach((page, i) => {
        let icon = "";
        if (i === 0) {
          if (page === "reporting") {
            icon = "insights";
          } else if (page === "manage") {
            icon = "tune";
          } else if (page === "inventory") {
            icon = "grid_view";
          } else if (page === "ads") {
            icon = "ad_units";
          }
        }

        let thisBreadcrumb = {
          to: i === 0 ? "" : "/" + pages.slice(0, i + 1).join("/")
        };
        if (i === 0) {
          thisBreadcrumb.icon = icon;
        } else {
          thisBreadcrumb.label =
            i === 0 ? "" : this.capitalizeText(page.replaceAll("-", " "));
        }

        breadcrumbs.push(thisBreadcrumb);
      });

      if (this.customCurrentPage.length > 0) {
        let currentPage = {
          label: this.customCurrentPage
        };
        if (this.overridePageLevel > 0) {
          breadcrumbs.splice(
            breadcrumbs.length - this.overridePageLevel,
            this.overridePageLevel - 1
          );
          breadcrumbs[breadcrumbs.length - 1] = currentPage;
        } else {
          breadcrumbs.push(currentPage);
        }
      }

      return breadcrumbs;
    }
  }
};
</script>

<style scoped></style>
